<template>
  <v-divider />
</template>

<script>
export default {
  name: 'DropdownMenuItemDivider'
};
</script>

<style></style>
